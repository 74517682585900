import React, {
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/Company/InfoBox';
import NoLogoPlaceholder from '../../assets/img/NoLogoPlaceholder.png';
// Images
import UpdateIcon from '../../assets/img/Upload_Logo_Icon.png';
// Clients
import { axiosPatch } from '../../client/axios';
// Constants
import {
  UPLOAD_LOGO_URL,
  VIEW_LOGO_URL,
} from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
// Components
import Container from '../CustomerPortal-Container';

type InfoBoxProps = {
  logoUrl?: string;
  avatarUrl?: string;
  handleEditClick: (e?: React.MouseEvent<HTMLDivElement>) => void;
};

const CompanyInfoBox = (props: InfoBoxProps) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // Local state
  const [ editCompanyLogo, setEditCompanyLogo ] = useState(false);
  const fileInput = React.createRef<any>();

  const isEditCompanySettingsAllowed = UserPermissionsHelper.isEditCompanySettingsAllowed();

  const handleUploadButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileUploadInputChange = async (e: any) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (file && file.size / 1024 / 1024 > 25) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_upload_logo_error_file_size',
          'Error uploading document: File exceeds 25MB!'
        ),
      });
      return;
    }

    // Package into form Data
    const formData = new FormData();
    formData.set('logo', file);

    try {
      dispatch({
        type: 'setBannerType',
        payload: 'info',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_upload_logo_info_upload_file',
          'Please wait while your file is being uploaded to the server. This may take a few minutes...'
        ),
      });

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const result = await axiosPatch(
        `${UPLOAD_LOGO_URL}`,
        state.companyId,
        await getAccessToken(),
        formData,
        config
      );

      if (
        result.data?.statusCode &&
        result.data?.statusCode === 201 &&
        result.data?.data?.companyLogo
      ) {
        dispatch({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: t(
            'company_upload_logo_logo_upload_success',
            'Company logo has been successfully updated.'
          ),
        });
        let companyLogo = '';
        if (
          result.data.data.storageAccountName &&
          result.data.data.companyLogo
        ) {
          companyLogo = `${result.data.data.storageAccountName}/${result.data.data.companyLogo}`;
        }
        dispatch({
          type: 'setCompanyLogo',
          payload: companyLogo,
        });

        props.handleEditClick();
        setEditCompanyLogo(false);
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.data
          ? `${t(
            'company_upload_logo_logo_upload_error',
            'Error uploading company logo'
          )}: ${err.response.data.data}`
          : t(
            'company_upload_logo_logo_upload_error',
            'Error uploading company logo'
          );
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
      console.log('Error uploading company logo');
    }
  };

  const logoPhoto = () => {
    if (state.companyLogo && state.companyLogo !== '') {
      return (
        <img
          src={`${VIEW_LOGO_URL}/${state.companyLogo}`}
          alt="Company Logo"
          className="InfoBox__Logo-File"
        />
      );
    }
    return (
      <img
        src={NoLogoPlaceholder}
        alt="Logo Placeholder"
        className="InfoBox__No-Logo"
        data-testid="CompanyInfoBox__noCompanyLogo"
      />
    );

  };

  return (
    <styles.InfoBox data-testid="CompanyInfoBox__wrapper">
      <Container className="InfoBox__Inner">
        <div className="InfoBox__Logo">
          <styles.InfoBoxLogoEditScreen
            className={`InfoBox__Logo-Edit-Screen ${
              editCompanyLogo && isEditCompanySettingsAllowed ? 'Active' : ''
            }`}
            onClick={
              editCompanyLogo && isEditCompanySettingsAllowed
                ? handleUploadButtonClick
                : undefined
            }
            onMouseEnter={() => setEditCompanyLogo(true)}
            onMouseLeave={() => setEditCompanyLogo(false)}
            data-testid="CompanyInfoBox__companyLogoContainer"
          >
            <img
              src={UpdateIcon}
              alt="Update Icon"
              className="InfoBox__Update-Icon"
            />
          </styles.InfoBoxLogoEditScreen>
          {logoPhoto()}
          <form className="CustomerPortalUploadForm">
            <input
              className="CustomerPortalUploadForm__Input"
              name="fileInput"
              type="file"
              onChange={handleFileUploadInputChange}
              ref={fileInput}
              data-testid="CompanyInfoBox__uploadForm"
            />
          </form>
        </div>
        <div
          className={`InfoBox__Company-Name ${
            isEditCompanySettingsAllowed ? 'InfoBox__Company-Name--Is-Editable' : ''
          }`}
        >
          <h2 className="InfoBox__Company-Name-Text Bold">
            {state.companyName}
          </h2>
        </div>
      </Container>
    </styles.InfoBox>
  );
};

export default CompanyInfoBox;
