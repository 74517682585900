import Button from '@mui/material/Button';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';

// Styles
import * as styles from '../assets/css/CustomerPortalSwitchAccountModal';
// interfaces
import type { IClientsList } from '../interfaces/browseAccounts.interface';
// Utils
import { UserPermissionsHelper } from '../lib/UserPermissions';
// Contexts
import { StoreContext } from '../store';
// Components
import Loader from './CustomerPortal-Loader';
import CustomerPortalModal from './Modal';

const SwitchAccountModal = (props: any) => {
  // translation method
  const { t } = useTranslation('common');
  // global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  // local state
  const [ loading, setLoading ] = useState(true);
  const [ accountsList, setAccountsList ] = useState<IClientsList[]>([]);
  // hooks
  const location = useLocation();
  const history = useHistory();
  // constants
  const canViewSwitchAccountModal: boolean = UserPermissionsHelper.isViewSwitchAccountModalAllowed();
  const title = state.lastSelectedAccount
    ? t('header_switch_account_modal_title', 'Switch Account')
    : t('header_switch_account_modal_change_account_title', 'Choose Account');
  const description = t(
    'header_switch_account_modal_description',
    'Browse through the list below and select the team portal you\'d like to switch.'
  );

  const handleCloseAccountSwitchModal = () => {
    dispatch({
      type: 'setShowSwitchAccountModal',
      payload: false,
    });
  };

  const setTeamContacts = (client: IClientsList) => {
    const totalContacts: number =
      (client.activeUsers ?? 0) +
      (client.pendingUsers ?? 0) +
      (client.disabledUsers ?? 0);

    if (totalContacts === 1) {
      return t('header_switch_account_modal_one_user_label', '1 User');
    }
    return t(
      'header_switch_account_modal_many_users_label',
      `${totalContacts} Users`,
      { totalContacts }
    );

  };

  const setSelectedAccount = (
    e: React.MouseEvent<HTMLDivElement>,
    id: string,
    name: string,
    isHapoEnabled: boolean,
    isUtoEnabled: boolean
  ) => {
    e.preventDefault();

    // If the user clicks on the same account that they're already on, close the modal
    if (id === state.companyId) {
      dispatch({
        type: 'showSelectedCompanyBanner',
        payload: true,
      });
      dispatch({
        type: 'setShowSwitchAccountModal',
        payload: false,
      });
      return;
    }

    // If the user is blocked, unblock them and let the checkUser function handle the rest
    if (state.isBlocked) {
      dispatch({
        type: 'setIsBlocked',
        payload: false,
      });
    }

    dispatch({
      type: 'setCompany',
      payload: {
        companyId: id,
        companyName: name,
        isHapoEnabled,
        isUtoEnabled,
      },
    });
    dispatch({
      type: 'showSelectedCompanyBanner',
      payload: true,
    });
    dispatch({
      type: 'setShowSwitchAccountModal',
      payload: false,
    });

    // Ensure user announcements are re-fetched when switching companies
    dispatch({
      type: 'setUserAnnouncements',
      payload: {
        announcements: [],
        fetchedAt: undefined,
      },
    });

    // Ensure that the next redirect waits until user is re-fetched before the App renders
    // (prevents flickering of pages rendered with previous company)
    dispatch({
      type: 'setFinishedInitialUserCheck',
      payload: false,
    });

    history.push('/');
  };

  useEffect(() => {
    if (canViewSwitchAccountModal || state.showSwitchAccountModal) {
      const accounts: IClientsList[] = state.userCompanies.map(
        ({ additionalCompanyInfo }) => ({
          id: additionalCompanyInfo?.id ?? '',
          name: additionalCompanyInfo?.name ?? '',
          activeUsers: additionalCompanyInfo?.activeUsers ?? 0,
          pendingUsers: additionalCompanyInfo?.pendingUsers ?? 0,
          disabledUsers: additionalCompanyInfo?.disabledUsers ?? 0,
          contacts: { totalSize: additionalCompanyInfo?.totalContacts ?? 0 },
          country: additionalCompanyInfo?.country ?? '',
          industry: additionalCompanyInfo?.industry ?? '',
          maintenanceFlag: additionalCompanyInfo?.maintenanceFlag ?? '',
          isHapoEnabled: additionalCompanyInfo?.hapo_feature_enabled ?? false,
          isUtoEnabled: additionalCompanyInfo?.uto_feature_enabled ?? false,
        })
      );

      setAccountsList(accounts);
      setLoading(false);
    }
  }, [ state.companyId, location.pathname ]);

  return (
    <CustomerPortalModal
      open={state.showSwitchAccountModal}
      testId="CustomerPortalSwitchAccount__modal"
      modalTitle={title}
      modalDescription={description}
      handleClose={handleCloseAccountSwitchModal}
      modalHeading={title}
    >
      <styles.SwitchAccountContainer>
        <p>{description}</p>
        {loading && (
          <div
            className="AccountList__loader"
            data-testid="SwitchAccountModal__loader"
          >
            <Loader />
          </div>
        )}
        {!loading && accountsList.length > 0 && (
          <div
            className="AccountList"
            data-testid="SwitchAccount__relatedAccountsList"
          >
            <div className="AccountList__header">
              <div className="AccountList__team">
                {t('header_switch_account_modal_column_team', 'Team')}
              </div>
              <div className="AccountList__teamSize">
                {t('header_switch_account_modal_column_team_size', 'Team Size')}
              </div>
            </div>
            <div className="AccountList__body">
              {accountsList.map((account) => (
                <div
                  key={account.id}
                  className="AccountList__item"
                  onClick={(e) => {
                    setSelectedAccount(
                      e,
                      account.id,
                      account.name,
                      account.isHapoEnabled,
                      account.isUtoEnabled
                    );
                  }}
                  data-testid="SwitchAccount__relatedAccount"
                >
                  <div className="AccountList__team">{account.name}</div>
                  <div className="AccountList__teamSize">
                    {setTeamContacts(account)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {!loading && !accountsList.length && (
          <div
            className="AccountList"
            data-testid="SwitchAccount__noRelatedAccountsFound"
          >
            <p>
              <b>
                {t(
                  'header_switch_account_modal_no_results',
                  'No related accounts found!'
                )}
              </b>
            </p>
          </div>
        )}

        <div className="AccountList__CTAs">
          <Button
            className="CancelButton"
            onClick={handleCloseAccountSwitchModal}
            data-testid="SwitchAccount__closeModal"
          >
            {t('header_switch_account_modal_cancel_btn', 'Cancel')}
          </Button>
        </div>
      </styles.SwitchAccountContainer>
    </CustomerPortalModal>
  );
};

export default SwitchAccountModal;
