import type { RoleWithMetadata } from '@customer-portal/constants';
import {
  DEFAULT_PERMISSIONS,
  UserCompanyAssociationType,
  UserPermission,
  UserRole,
} from '@customer-portal/constants';

import { getSortedEnumValues } from './index.util';

/**
 * Returns the list of roles sorted by how the enum values are defined
 */
export const getMergedAndSortedRoles = (roles: UserRole[]): UserRole[] => (
  getSortedEnumValues(
    UserRole,
    Array.from(new Set(roles)) as unknown as Array<(typeof UserRole)>
  ) as unknown as UserRole[]
);

/**
 * Returns the list of permissions sorted by how the enum values are defined
 */
export const getMergedAndSortedPermissions = (permissions: UserPermission[]): UserPermission[] => (
  getSortedEnumValues(
    UserPermission,
    Array.from(new Set(permissions)) as unknown as Array<(typeof UserPermission)>
  ) as unknown as UserPermission[]
);

/**
 * Returns the list of association types sorted by how the enum values are defined
 */
export const getMergedAndSortedAssociationTypes = (associationTypes: UserCompanyAssociationType[]): UserCompanyAssociationType[] => (
  getSortedEnumValues(
    UserCompanyAssociationType,
    Array.from(new Set(associationTypes)) as unknown as Array<(typeof UserCompanyAssociationType)>
  ) as unknown as UserCompanyAssociationType[]
);

/**
 * Returns a filtered list based on specific criteria for each role type (can include duplicates)
 */
export const getValidRoles = (roles: RoleWithMetadata[]): RoleWithMetadata[] => (
  roles.filter((r) => (
    r.isActive !== false &&
    (!r.expirationDate || new Date(r.expirationDate) > new Date())
  ))
);

/**
 * Returns the list of all permissions across all roles (can include duplicates)
 */
export const getMergedPermissions = (roles: RoleWithMetadata[]): UserPermission[] => (
  roles.flatMap((r) => ([
    ...(DEFAULT_PERMISSIONS[r.name] ?? []),
    ...(r.extraPermissions ?? []),
  ]))
);

/**
 * Returns if the user has the list of roles
 */
export const doesUserHaveRoles = ({
  adminUserRoles = [],
  userCompanyRoles = [],
  roles,
  match = 'all',
}: {
  adminUserRoles?: RoleWithMetadata[];
  userCompanyRoles?: RoleWithMetadata[];
  roles: UserRole[];
  match?: 'any' | 'all';
}): boolean => {
  if (!roles.length) {
    return true;
  }

  const allValidRoles: RoleWithMetadata[] = [
    ...getValidRoles(adminUserRoles),
    ...getValidRoles(userCompanyRoles),
  ];
  const allRolesForUser: Set<UserRole> = new Set(
    allValidRoles.map((r) => r.name)
  );

  return match === 'all'
    ? roles.every((r) => allRolesForUser.has(r))
    : roles.some((r) => allRolesForUser.has(r));
};

/**
 * Returns if the user has the list of permissions
 */
export const doesUserHavePermissions = ({
  adminUserRoles = [],
  userCompanyRoles = [],
  permissions,
  match = 'all',
}: {
  adminUserRoles?: RoleWithMetadata[];
  userCompanyRoles?: RoleWithMetadata[];
  permissions: UserPermission[];
  match?: 'any' | 'all';
}): boolean => {
  if (!permissions.length) {
    return true;
  }

  const allValidRoles: RoleWithMetadata[] = [
    ...getValidRoles(adminUserRoles),
    ...getValidRoles(userCompanyRoles),
  ];

  const allPermissionsForUser: Set<UserPermission> = new Set(
    getMergedPermissions(allValidRoles)
  );

  return match === 'all'
    ? permissions.every((p) => allPermissionsForUser.has(p))
    : permissions.some((p) => allPermissionsForUser.has(p));
};
